<template>
  <div>
    <div class="b-cuti pb-5">
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <router-link to="/keterlambatan/form">
            <b-button block class="w-100 f-11 bold button-sag"
              >Ajukan Keterlambatan</b-button
            >
          </router-link>
        </b-col>
      </b-row>
      <b-row no-gutters class="m-0">
        <b-col xs="6" class="p-3">
          <b-form-group
            label="Pilih Bulan"
            class="f-13"
            style="font-weight: bold"
          >
            <b-form-select
              :options="opt.bulan"
              class="w-100 f-13"
              v-model="form.data.bulan"
              @change="
                getHistoriAbsensi();
                getHistoriKeterlambatan();
              "
            />
          </b-form-group>
        </b-col>
        <b-col xs="6" class="p-3">
          <b-form-group
            label="Pilih Tahun"
            class="f-13"
            style="font-weight: bold"
          >
            <b-form-select
              :options="opt.tahun"
              class="w-100 f-13"
              v-model="form.data.tahun"
              @change="
                getHistoriAbsensi();
                getHistoriKeterlambatan();
              "
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <div class="bd-card rounded-3 bg-light">
            <div class="p-3 d-flex align-items-center f-14 bold">
              <b-icon icon="journal-check" class="text-primary mr-2" />
              <i class="bi bi-journal-check"></i> Histori Presensi
            </div>
            <div class="bd-card-content p-2">
              <div
                class="d-flex justify-content-center align-items-center flex-column p-5"
                v-if="form.loading"
              >
                <b-spinner
                  class="f-12"
                  type="grow"
                  label="Spinning"
                  style="background-color: #159895"
                ></b-spinner>
                <span class="f-11 mt-1 bold">Loading....</span>
              </div>

              <div v-else>
                <div class="d-flex flex-column" v-if="absensi.length > 0">
                  <b-tabs content-class="pt-1" align="center" justified>
                    <b-tab :title-link-class="'tab-title-class'" active>
                      <template #title>
                        <i class="mr-1 f-12">List </i>
                        <strong class="f-12">Presensi</strong>
                      </template>
                      <div
                        class="bd-card-item p-2 ml-1 mr-1 text-white mb-1"
                        :class="absensi.bg"
                        style="border-radius: 5px"
                        v-for="(absensi, absensiidx) in absensi"
                        :key="`absensi-${absensiidx}`"
                      >
                        <div class="d-flex justify-content-between">
                          <h6 class="m-0 p-0">{{ absensi.masuk }}</h6>
                          <h6>{{ absensi.keluar }}</h6>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                          <label class="m-0 p-0 f-12">{{
                            dateFormat(absensi.tanggal)
                          }}</label>
                          <span class="f-12">{{ absensi.keterangan }}</span>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab :title-link-class="'tab-title-class'">
                      <template #title>
                        <i class="mr-1 f-12">List </i>
                        <strong class="f-12">Keterlambatan</strong>
                      </template>
                      <div class="page-faq p-1">
                        <div class="accordion" role="tablist">
                          <!-- DISETUJUI -->
                          <b-card no-body class="mb-1">
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                block
                                v-b-toggle.accordion-1
                                class="text-left f-12 bold bg-card-disetujui"
                              >
                                <div class="float-left">
                                  <b-icon
                                    icon="patch-check-fill"
                                    class="text-white pr-1"
                                    style="fill: #159895"
                                  >
                                  </b-icon>
                                  Pengajuan Di setujui
                                </div>
                                <div class="float-right">
                                  <span
                                    class="f-10"
                                    style="
                                      padding: 2px 10px 2px 10px;
                                      color: white;
                                      border-radius: 50px;
                                      background-color: #159895;
                                    "
                                    >{{ jumlahDisetujui }}</span
                                  >
                                </div>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              id="accordion-1"
                              visible
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <div
                                class="bd-card-item p-2 mx-1 mt-1 text-white mb-0 bg-card-cus-content"
                                style="border-radius: 5px"
                                v-for="(abs, absidx) in terlambat.disetujui"
                                :key="`abs-${absidx}`"
                              >
                                <!-- kategori cuti -->
                                <h3 class="m-0 p-0 f-12 bold">
                                  {{ abs.kategori_terlambat }}
                                </h3>
                                <!-- keterangan -->
                                <span class="f-11">{{ abs.keterangan }}</span>
                                <!-- hari -->
                                <div
                                  class="d-flex justify-content-between mt-3"
                                >
                                  <h3 class="m-0 p-0 f-11">
                                    {{ dateFormat(abs.tgl_terlambat) }}
                                  </h3>
                                  <h3 class="m-0 p-0 f-11 bold">
                                    {{ abs.kategori_absen }}
                                  </h3>
                                </div>
                              </div>
                            </b-collapse>
                          </b-card>
                          <!-- END DISETUJUI -->

                          <!-- MENUNGGU VERIFIKASI -->
                          <b-card no-body class="mb-1">
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                block
                                v-b-toggle.accordion-2
                                class="text-left f-12 bold bg-card-pending"
                              >
                                <div class="float-left">
                                  <b-icon
                                    icon="patch-minus-fill"
                                    class="text-white pr-1"
                                    style="fill: #e55807"
                                  >
                                  </b-icon>
                                  Menunggu verifikasi
                                </div>
                                <div class="float-right">
                                  <span
                                    class="f-10"
                                    style="
                                      padding: 2px 10px 2px 10px;
                                      color: white;
                                      border-radius: 50px;
                                      background-color: #e55807;
                                    "
                                    >{{ jumlahPending }}</span
                                  >
                                </div>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              id="accordion-2"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <div
                                class="bd-card-item p-2 mx-1 mt-1 text-white mb-1 bg-card-cus-pending"
                                style="border-radius: 5px"
                                v-for="(abs, absidx) in terlambat.pending"
                                :key="`abs-${absidx}`"
                              >
                                <!-- kategori cuti -->
                                <h3 class="m-0 p-0 f-12 bold">
                                  {{ abs.kategori_terlambat }}
                                </h3>
                                <!-- keterangan -->
                                <span class="f-11">{{ abs.keterangan }}</span>
                                <!-- hari -->
                                <div
                                  class="d-flex justify-content-between mt-3"
                                >
                                  <h3 class="m-0 p-0 f-11">
                                    {{ dateFormat(abs.tgl_terlambat) }}
                                  </h3>
                                  <h3 class="m-0 p-0 f-11 bold">
                                    {{ abs.kategori_absen }}
                                  </h3>
                                </div>
                              </div>
                            </b-collapse>
                          </b-card>
                          <!-- END MENUNGGU VERIFIKASI -->

                          <!-- DITOLAK -->
                          <b-card no-body class="mb-1">
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                block
                                v-b-toggle.accordion-3
                                class="text-left f-12 bold bg-card-ditolak"
                              >
                                <div class="float-left">
                                  <b-icon
                                    icon="patch-exclamation-fill"
                                    class="text-white pr-1 f-13 bold icon-tolak"
                                  >
                                  </b-icon>
                                  Pengajuan Ditolak
                                </div>
                                <div class="float-right">
                                  <span class="f-10 span-tolak">{{
                                    jumlahDitolak
                                  }}</span>
                                </div>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              id="accordion-3"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <div
                                class="bd-card-item p-2 mx-1 mt-1 text-white mb-1 bg-card-cus-ditolak"
                                style="border-radius: 5px"
                                v-for="(abs, absidx) in terlambat.ditolak"
                                :key="`abs-${absidx}`"
                              >
                                <!-- kategori cuti -->
                                <h3 class="m-0 p-0 f-12 bold">
                                  {{ abs.kategori_terlambat }}
                                </h3>
                                <!-- keterangan -->
                                <span class="f-11">{{ abs.keterangan }}</span>
                                <!-- hari -->
                                <div
                                  class="d-flex justify-content-between mt-3"
                                >
                                  <h3 class="m-0 p-0 f-11">
                                    {{ dateFormat(abs.tgl_terlambat) }}
                                  </h3>
                                  <h3 class="m-0 p-0 f-11 bold">
                                    {{ abs.kategori_absen }}
                                  </h3>
                                </div>
                              </div>
                            </b-collapse>
                          </b-card>
                          <!-- DITOLAK -->

                          <!-- DIANULIR -->
                          <b-card no-body class="mb-1">
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                block
                                v-b-toggle.accordion-4
                                class="text-left f-12 bold bg-card-dianulir"
                              >
                                <div class="float-left">
                                  <b-icon
                                    icon="x-circle-fill"
                                    class="text-white pr-1"
                                    style="fill: #482121"
                                  >
                                  </b-icon>
                                  Pengajuan Dianulir HR
                                </div>
                                <div class="float-right">
                                  <span
                                    class="f-10"
                                    style="
                                      padding: 2px 10px 2px 10px;
                                      color: white;
                                      border-radius: 50px;
                                      background-color: #482121;
                                    "
                                    >{{ jumlahDianulir }}</span
                                  >
                                </div>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              id="accordion-4"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <div
                                class="bd-card-item p-3 mx-1 mt-1 text-white mb-0 bg-card-cus-dianulir"
                                style="border-radius: 5px"
                                v-for="(abs, absidx) in terlambat.dianulir"
                                :key="`abs-${absidx}`"
                              >
                                <!-- kategori cuti -->
                                <h3 class="m-0 p-0 f-12 bold">
                                  {{ abs.kategori_terlambat }}
                                </h3>
                                <!-- keterangan -->
                                <span class="f-11">{{ abs.keterangan }}</span>
                                <!-- hari -->
                                <div
                                  class="d-flex justify-content-between mt-3"
                                >
                                  <h3 class="m-0 p-0 f-11">
                                    {{ dateFormat(abs.tgl_terlambat) }}
                                  </h3>
                                  <h3 class="m-0 p-0 f-11 bold">
                                    {{ abs.kategori_absen }}
                                  </h3>
                                </div>
                              </div>
                            </b-collapse>
                          </b-card>
                          <!-- DIANULIR-->
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <div class="d-flex" v-else>
                  <div
                    class="bd-card-item p-2 text-white d-flex justify-content-center bg-card-cus-content-list w-100"
                  >
                    <span class="f-12 bold">Data presensi tidak ditemukan</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style>
.tab-title-class {
  text-transform: uppercase;
  font-size: 13px !important;
  color: black !important;
}
.tab-content {
  background-color: white !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 0 0 5px 5px;
}
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.bg-card-cus-content-list {
  border: none;
  background-color: #159895;
}
.bg-card-cus-pending-list {
  border: none;
  background-color: #f58840;
}
.button-sag {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #159895;
  border: none;
}
.button-sag:hover {
  background-color: #159895;
}
.bg-card-grey {
  border: none;
  color: black;
  background-color: #b2b2b2;
}
.bg-card-cus {
  background-color: #ecf9ff;
}

/* DISETUJUI */
.bg-card-disetujui {
  border: none;
  background-color: white !important;
  color: #159895;
}
.bg-card-cus-content {
  border: none;
  background-color: #17adaa;
}
.bg-card-disetujui:hover {
  border: none;
  background-color: #17adaa;
  color: white;
}
.bg-card-disetujui:focus {
  border: none;
  background-color: #17adaa;
  color: white;
}
/* END DISETUJUI */
/* PENDING */
.bg-card-pending {
  border: none;
  background-color: white !important;
  color: #e55807;
}
.bg-card-cus-pending {
  border: none;
  background-color: #f58840;
}
.bg-card-pending:hover {
  border: none;
  background-color: #f58840;
  color: white;
}
.bg-card-pending:focus {
  border: none;
  background-color: #f58840;
  color: white;
}

/* END PENDING */
/* DITOLAK */
.bg-card-ditolak {
  border: none;
  background-color: white !important;
  color: #b70404;
}
.bg-card-cus-ditolak {
  border: none;
  background-color: #bd4b4b;
}
.icon-tolak {
  fill: #b70404;
}
.span-tolak {
  padding: 2px 10px 2px 10px;
  color: white;
  border-radius: 50px;
  background-color: #b70404;
}
.bg-card-ditolak:focus {
  border: none;
  background-color: #bd4b4b !important;
  color: white !important;
}
.bg-card-ditolak:hover {
  border: none;
  background-color: #bd4b4b !important;
  color: white !important;
}
/* END DITOLAK */

/* DIANULIR */
.bg-card-dianulir {
  border: none;
  background-color: white;
  color: #482121;
}
.bg-card-cus-dianulir {
  border: none;
  background-color: #7a3e3e;
}
.bg-card-dianulir:hover {
  border: none;
  background-color: #7a3e3e;
  color: white;
}
.bg-card-dianulir:focus {
  border: none;
  background-color: #7a3e3e;
  color: white;
}
/* END DIANULIR */
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      jumlahDisetujui: 0,
      jumlahPending: 0,
      jumlahDitolak: 0,
      jumlahDianulir: 0,
      absensi: [],
      terlambat: {
        disetujui: [],
        pending: [],
        ditolak: [],
        dianulir: [],
      },
      form: {
        data: {
          bulan: new Date().getMonth() + 1,
          tahun: new Date().getFullYear(),
        },
        loading: false,
      },
      opt: {
        bulan: [
          {
            value: "00",
            text: "Pilih Bulan",
          },
          {
            value: "01",
            text: "Januari",
          },
          {
            value: "02",
            text: "Februari",
          },
          {
            value: "03",
            text: "Maret",
          },
          {
            value: "04",
            text: "April",
          },
          {
            value: "05",
            text: "Mei",
          },
          {
            value: "06",
            text: "Juni",
          },
          {
            value: "07",
            text: "Juli",
          },
          {
            value: "08",
            text: "Agustus",
          },
          {
            value: "09",
            text: "September",
          },
          {
            value: "10",
            text: "Oktober",
          },
          {
            value: "11",
            text: "November",
          },
          {
            value: "12",
            text: "Desember",
          },
        ],
        tahun: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getHistoriKeterlambatan() {
      this.form.loading = true;
      let url = `/presence/list_keterlambatan`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      payload.append(
        "periode",
        `${this.form.data.bulan}-${this.form.data.tahun}`
      );
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let disetujui = [];
        let pending = [];
        let ditolak = [];
        let dianulir = [];
        req.data.msg.map((x) => {
          if (x.setatus == "1") {
            disetujui.push(x);
          } else if (x.setatus == "0") {
            pending.push(x);
          } else if (x.setatus == "2") {
            ditolak.push(x);
          } else {
            dianulir.push(x);
          }
        });
        this.terlambat.disetujui = disetujui;
        this.terlambat.pending = pending;
        this.terlambat.ditolak = ditolak;
        this.terlambat.dianulir = dianulir;
        this.jumlahDisetujui = this.terlambat.disetujui.length;
        this.jumlahPending = this.terlambat.pending.length;
        this.jumlahDitolak = this.terlambat.ditolak.length;
        this.jumlahDianulir = this.terlambat.dianulir.length;

        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    async getHistoriAbsensi() {
      this.form.loading = true;
      let url = "/presence/presence_detail";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      payload.append(
        "periode",
        `${this.form.data.bulan}-${this.form.data.tahun}`
      );
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let absensi = [];
        req.data.msg.map((x) => {
          let bg =
            x.keterangan == "Hadir"
              ? "bg-card-cus-content-list"
              : "bg-card-cus-pending-list";
          x.bg = bg;
          absensi.push(x);
        });
        this.absensi = absensi;
        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    dateFormat(date) {
      let theDate = new Date(date);
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      let day = theDate.getDay();
      let d = theDate.getDate();
      let m = theDate.getMonth() + 1;
      let y = theDate.getFullYear();
      return `${days[day]}, ${d}-${m}-${y}`;
    },
  },
  mounted() {
    this.getHistoriKeterlambatan();
    this.getHistoriAbsensi();
    this.form.data.bulan = this.pad(this.form.data.bulan, 2);
  },
  created() {
    let thisYear = new Date().getFullYear() + 1;
    for (let x = thisYear; x > 1990; x--) {
      let tahun = {
        value: x,
        text: x,
      };
      this.opt.tahun.push(tahun);
    }
  },
};
</script>